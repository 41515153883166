import { Box, Stack } from '@mui/material';

import { ReactComponent as HeaderLogoSvg } from '../Icons/Hyve-Logo.svg';

type HeaderLogoProps = {
  width?: number;
  beta?: boolean;
};

const HeaderLogo = ({ width, beta = true }: HeaderLogoProps) => {
  return (
    <Stack direction='row' width={width} pl={2.5} alignItems='center' spacing='12px'>
      <HeaderLogoSvg />
      {beta && (
        <Box color='#666' border='1px solid #666' padding='0px 6px' borderRadius='10px' fontSize='10px'>
          BETA
        </Box>
      )}
    </Stack>
  );
};

export default HeaderLogo;
